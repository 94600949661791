import React from 'react';
import { withUser } from '../../../../contexts/User/withUser';
import { IUserProviderProps } from '../../../../contexts/User/UserProvider';
import { ICashierPaymentsApi } from '@wix/cashier-payments-widget';
import { withLocation } from '../../../../contexts/Location/withLocation';
import { ILocationProviderProps } from '../../../../contexts/Location/LocationProvider';
import { Pages } from '../../../../contexts/Location/LocationContext';
import { withChallengeData } from '../../../../contexts/ChallengeDataProvider/withChallengeData';
import { applyProviders } from '../../../../services/applyProviders';
import { PaymentWidget } from './PaymentWidget';
import { GeneralDataContext } from '../../../../contexts/GeneralDataProvider/GeneralDataContext';
import { getOrderId } from '../../../../selectors/participants';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { IChallengeDataProps } from '../../../../contexts/ChallengeDataProvider/helpers';
import { DeviceType } from '../../../../editor/types/common';
import { UserState } from '../../../../contexts/User/UserContext';

export interface IContainerProps {
  onApiReady(api: ICashierPaymentsApi): void;
  onWidgetLoaded(): void;
}

let requestInProgress = false;

type IPaymentContainerProps = IUserProviderProps &
  IContainerProps &
  ILocationProviderProps &
  IChallengeDataProps;

export const PaymentWidgetContainer: React.FC<IContainerProps> = applyProviders(
  (props: IPaymentContainerProps) => {
    const { appDefinitionId, language, isMobile } = useEnvironment();
    const {
      instanceId,
      instance,
      host,
      msid,
      defaultBIParams: { siteOwnerId },
    } = React.useContext(GeneralDataContext);
    const challenge = props.challengeData?.challenge || {};
    const slug = challenge?.settings?.seo?.slug;

    if (!slug) {
      return null;
    }

    const amount = challenge?.settings?.pricing?.singlePayment?.price?.amount;
    const currency =
      challenge?.settings?.pricing?.singlePayment?.price?.currency;

    if (!props.user || !getOrderId(props.participant)) {
      props.goToPage({
        pageId: Pages.Details,
        challengeId: slug,
      });
      return null;
    }

    if (!props.participant && !requestInProgress) {
      requestInProgress = true;
    }

    return (
      <PaymentWidget
        siteOwnerId={siteOwnerId}
        appId={appDefinitionId}
        userId={props.user.id}
        instanceId={instanceId}
        instance={instance}
        host={host}
        deviceType={isMobile ? DeviceType.Mobile : DeviceType.Desktop}
        msid={msid}
        locale={language}
        orderId={getOrderId(props.participant)}
        onApiReady={props.onApiReady}
        onWidgetLoaded={props.onWidgetLoaded}
        amount={amount}
        currency={currency}
        pendingOwnerApprove={() => {
          props.goToPage({
            pageId: Pages.Details,
            challengeId: slug,
            queryParams: {
              forceState: UserState.PAYMENT_PENDING,
            },
          });
        }}
        paymentComplete={() => {
          props.goToPage({
            pageId: Pages.ThankYou,
            challengeId: slug,
          });
        }}
      />
    );
  },
  [withChallengeData, withLocation, withUser],
);

PaymentWidgetContainer.displayName = 'PaymentWidgetContainer';
